import React, { useState, useEffect, Fragment } from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import Layout from "../components/layout"
import Head from "../components/Head"
import Header from "../components/header"
import SEO from "../components/seo"

const Gallery = props => {
  const data = useStaticQuery(graphql`
    query {
      HWGraphQL {
        page(id: "cGFnZToxMQ==") {
          acfKategorije {
            kategorijeZaGalerijo {
              fieldGroupName
              kategorija
              kategorija2
              kategorija3
              kategorija4
              kategorija5
              kategorija6
            }
          }
        }
        mediaItems {
          edges {
            node {
              id
              sourceUrl
              acfSlike {
                kategorija
              }
            }
          }
        }
      }
    }
  `)
  const {
    HWGraphQL: {
      page: {
        acfKategorije: { kategorijeZaGalerijo },
      },
      mediaItems,
    },
  } = data
  console.log("galerija", data)
  console.log("galerija props", props)
  return (
    <Fragment>
      <SEO title="Galerija" />
      <Head />
      <Header />
      <div className="redboa-side-content">
        <section className="portfolio pt-120 pb-120 pos-re">
          <div className="container">
            <div className="row">
              <div className="col-md-10 offset-md-1 mb-40 text-center">
                <h6 className="sub-title">REDBOA</h6>
                <h4 className="title">Gallery</h4>
              </div>
            </div>
            <div className="row">
              <div className="col-md-10 offset-md-1 text-center">
                <div className="row">
                  <div className="filtering text-center mb-20 col-sm-12">
                    {kategorijeZaGalerijo.kategorija ? (
                      <span
                        data-filter={"." + kategorijeZaGalerijo.kategorija}
                        className="active"
                      >
                        {" "}
                        Pice
                      </span>
                    ) : (
                      false
                    )}

                    {kategorijeZaGalerijo.kategorija2 ? (
                      <span
                        data-filter={"." + kategorijeZaGalerijo.kategorija2}
                      >
                        {" "}
                        SOLATE
                      </span>
                    ) : (
                      false
                    )}

                    {kategorijeZaGalerijo.kategorija3 ? (
                      <span
                        data-filter={"." + kategorijeZaGalerijo.kategorija3}
                      >
                        {" "}
                        Ambient
                      </span>
                    ) : (
                      false
                    )}
                  </div>
                  <div className="clearfix"></div>

                  <div className="gallery text-center full-width">
                    {mediaItems.edges.map(edge => {
                      return (
                        <div
                          className={`col-md-4 items ${edge.node.acfSlike.kategorija}`}
                        >
                          <a href={edge.node.sourceUrl} className="popimg">
                            <div className="item-img">
                              <img src={edge.node.sourceUrl} alt="image" />
                              <div className="item-img-overlay valign">
                                <div className="overlay-info full-width vertical-center">
                                  <h6>{edge.node.acfSlike.kategorija}</h6>
                                </div>
                              </div>
                            </div>
                          </a>
                        </div>
                      )
                    })}

                    <div className="clear-fix"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Fragment>
  )
}

export default Gallery
